"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.screens = void 0;
exports.screens = {
    login: {
        screenTitle: "Log in to",
        screenSubtitle: "Your Account",
        info: "Enter your email address and password to access your jobs",
        forgotPassword: "Forgot password?",
    },
    changePassword: {
        screenTitle: "Forgot Password?",
        screenSubtitle: "Enter New Password",
        info: "Enter your new password. This should contain at least one capital letter and one number, and must be a minimum of 6 characters in length.",
    },
    forgotPassword: {
        screenTitle: "Forgot Password?",
        screenSubtitle: "Reset Your Password",
        info: "Enter the email associated with your account, and we'll send you an email to reset your password.",
        button: "Send email",
    },
    resetPassword: {
        screenTitle: "Forgot Password?",
        screenSubtitle: "Reset Your Password",
        verificationCodeInfo: "We have sent a verification code to your email address. Use this code to reset your password",
        get info() {
            return exports.screens.changePassword.info;
        },
        button: "Confirm",
    },
    dashboard: {
        screenTitle: "Dashboard",
        salutation: "Hey {{name}}!",
        jobsSummary: "You have {{jobsCount}} jobs today",
        jobCompletionStatement: "Jobs can only be completed in the order they are presented",
        JobSummaryPanel: {
            button: "Accept Job",
            distance: "miles away",
        },
    },
};
