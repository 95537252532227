"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.screens = void 0;
exports.screens = {
    basket: {
        title: "Your Basket",
        navTitle: "Basket",
        totalOrder: "Total Order",
        emptyBasket: "Your basket is empty",
        exploreServices: "Explore our rubbish removal services.",
        buttons: {
            viewServices: "View our services",
        },
        noPostcode: "No Postcode",
        bookMoreServices: "Need to book more\nwaste services?",
        basketTotal: "Basket total",
        weAccept: "We accept:",
        basketError: "There is a problem with the request, empty your basket and try again",
    },
    book: {
        title: "Select Your Service",
        navTitle: "Book",
    },
    selectProduct: {
        title: "Select Product",
        gotLightWaste: "Got light waste and need it gone quick?",
        tryManVan: "Try Man & Van",
        nextStep: "Next step",
        bulkAddDescription: "Select all of your items before the next step",
        filterItems: "Filter your items",
        searchError: "No items found for `{{searchTerm}}`",
        noItemsSelected: "No items have been selected",
        within4m: "Within 4 metres of a road",
        between4and10: "Between 4 and 10 metres of a road",
        notAllowedTypeWarning: "Unfortunately, this type of waste is not suitable for a skip bag. Instead you can use 4-8 yard skip for limited amounts of heavy or dense waste, get a quote on ",
        plasterboardWarning: "Please email us at info@clearabee.co.uk to discuss your collection.",
        plasterboardEmailSubject: "Skip bag containing plasterboard",
        tilesWarning: "Unfortunately, a skip bag will not be suitable if you have 5 or more bin bags worth of tiles. Instead you can use 4-8 yard skips for limited amounts of dense waste, get a quote on ",
        skipHireLink: "skip hire.",
        readyToCollect: "My skip bag has been filled and is ready for collection now.",
        locationText: "In order for us to collect your skip bag safely we need to know where it is located.",
        handballCharge: "If your bag is heavy and more than 4 metres away from the road, a {{title}} of £{{price}} will apply at checkout.",
        selectJobDisclaimer: "Select which order below you'd like us to collect.",
        selectSkipExchangeDisclaimer: "Select which skip you'd like us to exchange.",
        whatsIncluded: "What's included",
    },
    checkout: {
        title: "Checkout",
        billingDetails: "Billing Details",
        cardDetails: "Card Details",
        gotDiscount: "Got a discount code?",
        apply: "Apply",
        orderSummary: "Order Summary",
        checkoutButton: "Checkout Securely",
        confirmBooking: "Confirm Booking",
        editBasket: "Edit Basket",
        labels: {
            sameAddressCollection: "Use the collection address for my billing address",
            sameAddressDelivery: "Use the delivery address for my billing address",
            businessToggle: "Booking on behalf of a business?",
            useBeloyal: "Use Beeloyal Credits",
        },
        loadingModal: {
            title: "Your payment is being processed",
            content: "We're processing your payment \nplease do not close this window.",
            noChargeTitle: "Your booking is being processed",
            noChargeContent: "We're processing your booking \nplease do not close this window.",
        },
        errorModal: {
            title: "An error occured",
            content: "There was an error processing your order.\nContact us to resolve this issue.",
        },
        wantToSaveMoney: "Want to save money?",
        signUpForBeeLoyal: "Sign up for BeeLoyal and earn\nup to 10% account credit!",
    },
    productDetails: {
        title: "Product Details",
        furnitureCondition: "Furniture Condition",
        free: "Free",
        contactDetails: "Contact Details",
        collectionDetails: "Collection Details",
        deliveryDetails: "Delivery Details",
        dateAndTimeslot: "Collection Date & Time Slot",
        extras: "Extras",
        privateLandConfirmation: "I confirm the skip will be on a private land",
        formErrors: "There are issues with the information you have entered, please double check the fields above",
        extrasTooltip: "Some items incur additional charges, please ensure you add any necessary extras before proceeding",
        prohibitedItemsTooltip: "We cannot take all items, please ensure you do not place an order for any prohibited items",
        redirectItemsTooltip: "We recommend using our ",
        redirectItemsTooltipEach: "{{service}} service for {{ keywords }}",
        prohibitedItems: {
            title: "Prohibited Items",
            heading: "We cannot take these items",
            list: {
                asbestos: "Asbestos",
                paintOrPaintCans: "Paint or paint cans (unless empty)",
                liquidChemicalsOrSolvents: "Liquid chemicals or solvents",
                carBatteries: "Car batteries",
                rawMeatOrInPackaging: "Raw meat or meat unless in sealed packaging",
                clinicalOrBiologicalWaste: "Clinical or biological waste",
                fireExtinguishers: "Fire extinguishers",
                gasBottles: "Gas bottles",
                oilsOrPetrol: "Oils or petrol",
            },
            message: "if you have chest freezers, air conditioning units, pianos.",
        },
        doNotShowPrompt: "Do not show prompt again",
        haveAnAccount: "Have a BeeLoyal account?",
        loginPrompt: "Login now to earn credits",
        chooseSlot: "Choose your time slot",
        exchangeDescription: "Select the date that you want your original skip to be collected and exchanged for your new skip. Then select a date for your new skip to be collected",
        collectionRangeDescription: "must be within {{ days }} days of delivery",
        basketError: "There is a problem with the request, empty your basket and try again",
        successModal: {
            addedToBasket: "Successfully added to your basket.",
            proceed: "Proceed to payment",
            continueShopping: "Continue shopping",
            viewBasket: "View Basket",
            noChargeProceed: "Proceed with booking",
        },
        calloutCharge: "{{ calloutCharge }} call-out charge",
        handballCharge: "{{ handballCharge }} handball charge",
        uploadPhoto: "Upload photo",
        photoExplanation: "Photos of your collection help to prepare our teams prior to arrival.",
        skipPhotoExplanation: "Upload a photograph of where you would like the skip to be placed.",
    },
    successScreen: {
        title: "Booking Successful",
        otherServicesYouMayLike: "Other services you may like...",
        fromPrice: "From {{price}}",
        refNumber: "Order Reference Number",
        infoMessage: "Thank you for your purchase, you will get a confirmation email shortly and we will send you an SMS when your services are on the way.",
        myAccount: "My account",
        backToHomepage: "Back to homepage",
        bookMore: "Book more",
        over: "Over",
        treesPlanted: "trees planted across three UK forests",
        lessThan: "Less than",
        miles: "{{ miles }} miles",
        toEachJob: "to each job and decreasing",
        carbonNeutral: "Carbon neutral",
        lightweight: "Lightweight",
        chassis: "chassis",
        reducesCarbon: "further reduces carbon",
        faqs: "FAQs",
        backToDashboard: "Back to Dashboard",
    },
    login: {
        title: "Login",
    },
    register: {
        title: "Register",
    },
    passwordReset: {
        title: "Forgot Password",
        subtitle: "Enter Your Email",
        submit: "Send Email",
        headerText: "Enter the email associated with your account and we'll send you an email to reset your password.",
    },
    passwordResetSubmit: {
        title: "Forgot Password",
        subtitle: "Enter New Password",
        successTitle: "Account Password",
        successSubtitle: "Reset Successfully",
        submit: "Confirm",
        successfulChange: "Password has been changed successfully",
    },
    dashboard: {
        title: "Home",
        navTitle: "Dashboard",
        signUpForBeeloyal: "Sign up for Beeloyal",
        userDashboard: {
            upcomingCollection: "Upcoming collection",
            previousCollection: "Previous collection",
            timeslotPlaceholder: "8am - 8pm",
            daysToGo: "days to go",
            daysAgo: "days ago",
            gotYouCovered: "We've got you covered",
            bookService: "Book a service",
            bookNow: "Book Now",
            arrivingTomorrow: "Arriving tomorrow",
            arrivingToday: "Arriving today",
        },
    },
    jobHistory: {
        title: "Job History",
    },
    job: {
        title: "Job Reference {{- ref }}",
    },
    myAccount: {
        title: "My Account",
        personalDetails: "Personal Details",
        manageMyProfile: "Manage My Profile",
        manageMyCredentials: "Manage My Credentials",
        deleteAccount: "Delete Account",
        dangerZone: "Danger Zone",
        managePassword: "Manage Password",
        manageDetails: "Manage Details",
    },
    profileDetails: {
        title: "Profile Details",
        personalDetails: "Personal Details",
        manageMyProfile: "Manage My Profile",
        subscribeToMarketing: "Send info about discounts, new services and major updates",
        successMessage: "You have successfully updated your details",
    },
    changePassword: {
        title: "Change Password",
        invalidCredentials: "Invalid password",
        successMessage: "You have successfully changed your password",
        manageMyCredentials: "Manage My Credentials",
    },
    deleteAccount: {
        title: "Delete Account",
        info: "Once removed, we cannot recover your account. You'll receive an email confirming your action.",
        button: "Delete my account",
        confirmHeading: "Delete My Account",
        confirmText: 'Confirm your action by typing "delete" below',
    },
    notificationPreferences: {
        title: "Notification Preferences",
    },
    bulkAdd: {
        title: "Bulk Add",
        atLeastOneItem: "Please select at least one item",
        formErrors: "There are issues with the information you have entered, please double check the fields above",
        basketError: "There is a problem with the request, empty your basket and try again",
        skipBagNotice: {
            notSuitableHeading: "The following are not suitable for skip bags:",
            notSuitableItems: "Brick, Rubble, Soil and 5 or more bin bags filled with Tiles.",
            otherServices: "If you have these, use one of our other services.",
            tryManVan: "Try Man & Van",
        },
    },
};
