import { theme } from "../../../../theme";
import { StyleSheet } from "../../../../utils";

export const styles = StyleSheet({
  input: {
    border: 0,
    outline: 0,
    cursor: "default",
    textAlign: "center",
    width: theme.spacing.large,
    backgroundColor: "transparent",
    padding: `0px ${theme.spacing.xsmall2}`,
  },
  inputSmall: {
    ...theme.fontDefaults.small,
  },
  inputLarge: {
    ...theme.fontDefaults.base,
  },
  button: {
    border: 0,
    borderRadius: 100,
    cursor: "pointer",
    color: theme.colors.light.base,

    ":disabled": {
      backgroundColor: theme.colors.greyscale.lightest,
    },
  },
  buttonSmall: {
    width: theme.spacing.medium,
    height: theme.spacing.medium,
    ...theme.fontDefaults.small,
  },
  buttonLarge: {
    width: theme.spacing.xlarge,
    height: theme.spacing.xlarge,
    ...theme.fontDefaults.xlarge,
  },
});
