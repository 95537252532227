"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.screens = void 0;
exports.screens = {
    addVehicle: {
        screenTitle: "Add Vehicle",
        info: "Request new vehicles to be approved. Vehicles pending approval and approved vehicles can be viewed in the Vehicles Tab.",
        photoInfo: "We require a photos of all four sides of your vehicle. Ensure that the registration plate is clearly visible within the front and rear photo.",
        vehicleType: {
            luton: "Luton",
            caged: "Caged",
            maxi: "Maxi",
        },
        vehicleTypeLabel: "Select your vehicle type",
    },
    createWasteTransferNote: {
        screenTitle: "Create A W.T.N.",
        info: "Sign within the box",
    },
    dashboard: {
        screenTitle: "Jobs",
    },
    forgotPassword: {
        screenTitle: "Forgot Password",
        info: "Enter the email address associated with your account to receive an email that will contain a reset password verification code.",
    },
    resetPassword: {
        screenTitle: "Choose Your Password",
        verificationCodeInfo: "We have sent a verification code to your email address. Use this code to reset your password.",
        info: "Your new password must use at least one capital letter and one number.",
        invalidPassword: "Invalid password",
        matchPassword: "Passwords must match",
    },
    changePassword: {
        screenTitle: "Choose Your Password",
        info: "Your new password must use at least one capital letter and one number.",
        invalidPassword: "Invalid password",
        matchPassword: "Passwords must match",
    },
    jobCompletion: {
        screenTitle: "Complete Job",
        info: "In order to successfully complete a job all of the sections below must be completed correctly and in a timely manner. Please note that if a section is closed, no values will be submitted for that section.",
        buttons: {
            requestIncrease: "Request an Increase",
        },
    },
    jobDetails: {
        screenTitle: "Job Details",
        mustBeAcceptedBefore: "This job must be accepted before",
        noVehiclesWarning: "You currently have no approved and enabled vehicles, you can't accept this job.",
        modalDirectionsCopy: "How would you like to navigate?",
        modalViewMapCopy: "How would you like to view the location?",
        reassignJob: "Reassign this job",
        train: {
            accepted: "Accepted",
            onWay: "On The Way{{conditionalText}}",
            started: "Started{{conditionalText}}",
            collected: "Collected{{conditionalText}}",
            tipped: "Tipped{{conditionalText}}",
            completed: "{{conditionalText}}Completed",
        },
    },
    jobDetailsForm: {
        screenTitle: "Add Data For This Job",
        returnLink: "Return to Job Details",
        anyLeftovers: "Were there any {{wasteType}} not collected?",
        anyWasteType: "Did you collect any {{wasteType}}?",
        goingAhead: "Is the job going ahead?",
        failedOptions: {
            notReady: "The waste was not ready",
            noAccess: "No access to the waste",
            noWaste: "No waste to collect",
            other: "Other",
        },
    },
    jobsListing: {
        screenTitle: "Jobs List",
        contractor: "Contractor",
    },
    login: {
        screenTitle: "Login",
        title: "Clearabee",
        subtitle: "Partners App",
        info: "Manage your jobs and vehicles with the Clearabee Partners App.",
        forgotPassword: "Forgot Password?",
    },
    newUser: {
        screenTitle: "Register User",
        info: "Complete the form to register a new user to your team.",
        wasteCarriersLicence: "Waste Carrier's Licence",
        register: "Register",
        successMessage: "User added successfully!",
    },
    selectVehicle: {
        screenTitle: "Select Vehicle",
        info: "Remember, you must complete this job on {{collectionDate}}",
    },
    settings: {
        screenTitle: "Settings",
        infoHeading: "Spotted something?",
        info: "If you require any of these details amending, please email {{email}}",
    },
    users: {
        screenTitle: "User Management",
        deleteUser: "Delete this user?",
        deleting: "Deleting...",
        noUsers: "You have no users.",
    },
    vehicles: {
        screenTitle: "Vehicle Management",
        info: "Request new vehicles for approval and manage your approved vehicles here.",
        noVehicles: "You currently have no vehicles.",
    },
    viewVehicle: {
        screenTitle: "View Vehicle",
    },
};
